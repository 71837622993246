import React, { FC, useState } from 'react';
import * as SettingsService from './../services/settings.service';
import * as SettingsTypes from './../types/settings.types';
import i18nInstance from './../i18n';

export interface localeReason {
  reasonId: number;
  text: string;
}
export interface SettingsContext {
  primaryColor: string | null;
  secondaryColor: string | null;
  primaryContrastColor: string | null;
  customerImprint: string;
  customerPrivacyPolicy: string;
  backgroundUrl: string;
  logoUrl: string;
  descriptions: SettingsTypes.Description[];
  customer: string;
  processTypeId: number;
  languages: SettingsTypes.Language[];
  chosenLanguage: SettingsTypes.IsoCountryCode;
  loginDescription: string;
  retoureDescription: string;
  reasons: SettingsTypes.Reason[];
  localeReasons: localeReason[];
  actions: {
    getSettings: (customer: string, processTypeId: number) => Promise<boolean>;
    setLanguage: (isoCode: SettingsTypes.IsoCountryCode) => void;
  };
  forceEmptyReturnShipment: boolean;
}

export const initialSettingsContext: SettingsContext = {
  primaryColor: '',
  primaryContrastColor: '',
  secondaryColor: '',
  customerImprint: '',
  customerPrivacyPolicy: '',
  backgroundUrl: '',
  logoUrl: '',
  descriptions: [],
  customer: '',
  processTypeId: 0,
  languages: [],
  chosenLanguage: SettingsTypes.IsoCountryCode.EN,
  loginDescription: '',
  retoureDescription: '',
  reasons: [],
  localeReasons: [],
  actions: {
    getSettings: async () => {
      return false;
    },
    setLanguage: () => {},
  },
  forceEmptyReturnShipment: false,
};

export const settingsContext = React.createContext<SettingsContext>(
  initialSettingsContext,
);

export interface SettingsProviderProps {
  children: React.ReactNode;
}

const SettingsProvider: FC<SettingsProviderProps> = (props) => {
  const [primaryColor, setPrimaryColor] = useState<string | null>('');
  const [primaryContrastColor, setPrimaryContrastColor] = useState<
    string | null
  >('');
  const [secondaryColor, setSecondaryColor] = useState<string | null>('');
  const [backgroundUrl, setBackgroundUrl] = useState<string>('');
  const [logoUrl, setLogoUrl] = useState<string>('');
  const [descriptions, setDescriptions] = useState<SettingsTypes.Description[]>(
    [],
  );
  const [loginDescription, setLoginDescription] = useState<string>('');
  const [retoureDescription, setRetoureDescription] = useState<string>('');
  const [customer, setCustomer] = useState<string>('');
  const [processTypeId, setProcessTypeId] = useState<number>(0);
  const [languages, setLanguages] = useState<SettingsTypes.Language[]>([]);
  const [chosenLanguage, setChosenLanguage] =
    useState<SettingsTypes.IsoCountryCode | null>(null);

  const [reasons, setReasons] = useState<SettingsTypes.Reason[]>([]);
  const [localeReasons, setLocaleReasons] = useState<localeReason[]>([]);
  const [customerImprint, setCustomerImprint] = useState<string>('');
  const [customerPrivacyPolicy, setCustomerPrivacyPolicy] =
    useState<string>('');
  const [forceEmptyReturnShipment, setForceEmptyReturnShipment] =
    useState<boolean>(false);

  const setLoginDescriptionByIsoCode = (
    isoCode: SettingsTypes.IsoCountryCode,
  ) => {
    if (
      descriptions.find(
        (description: SettingsTypes.Description) =>
          description.isoCountryCode === isoCode &&
          description.type === SettingsTypes.DescriptionTypes.DESCRIPTION1,
      )
    ) {
      setLoginDescription(
        descriptions[
          descriptions.findIndex(
            (description: SettingsTypes.Description) =>
              description.isoCountryCode === isoCode &&
              description.type === SettingsTypes.DescriptionTypes.DESCRIPTION1,
          )
        ].text,
      );
    } else {
      setLoginDescription('');
    }
  };

  const setRetoureDescriptionByIsoCode = (
    isoCode: SettingsTypes.IsoCountryCode,
  ) => {
    if (
      descriptions.find(
        (description: SettingsTypes.Description) =>
          description.isoCountryCode === isoCode &&
          description.type === SettingsTypes.DescriptionTypes.DESCRIPTION2,
      )
    ) {
      setRetoureDescription(
        descriptions[
          descriptions.findIndex(
            (description: SettingsTypes.Description) =>
              description.isoCountryCode === isoCode &&
              description.type === SettingsTypes.DescriptionTypes.DESCRIPTION2,
          )
        ].text,
      );
    } else {
      setRetoureDescription('');
    }
  };

  const setLocaleReasonsByIsoCode = (isoCode: SettingsTypes.IsoCountryCode) => {
    let newLocaleReasons: localeReason[] = [];
    for (let reason of reasons) {
      if (
        reason.translations.find(
          (translation: SettingsTypes.translation) =>
            translation.countryCode === isoCode,
        )
      ) {
        newLocaleReasons.push({
          reasonId: reason.reasonId,
          text: reason.translations[
            reason.translations.findIndex(
              (translation: SettingsTypes.translation) =>
                translation.countryCode === isoCode,
            )
          ].translation,
        });
      }
      //Uncomment for fallback --> if no translation is provided
      /*       else
        newLocaleReasons.push({
          reasonId: reason.reasonId,
          text: "TranslationError",
        }); */
    }
    setLocaleReasons(newLocaleReasons);
  };

  const getSettings = async (
    customer: string,
    processTypeId: number,
  ): Promise<boolean> => {
    setCustomer(customer);
    setProcessTypeId(processTypeId);
    try {
      const settings = await SettingsService.getSettings(
        customer,
        processTypeId,
      );
      setPrimaryColor(settings.primaryColor);
      setPrimaryContrastColor(settings.primaryContrastColor);
      setSecondaryColor(settings.secondaryColor);
      setBackgroundUrl(settings.backgroundUrl);
      setLogoUrl(settings.logoUrl);
      setDescriptions(settings.descriptions);
      setLanguages(settings.languages);
      setReasons(settings.reasons);
      setForceEmptyReturnShipment(settings.forceEmptyReturnShipment);

      settings.customerImprint
        ? setCustomerImprint(settings.customerImprint)
        : setCustomerImprint('#');
      settings.customerPrivacyPolicy
        ? setCustomerPrivacyPolicy(settings.customerPrivacyPolicy)
        : setCustomerPrivacyPolicy('#');

      const navigatorLanguage = navigator.language.toUpperCase().substr(0, 2);

      if (
        navigatorLanguage in SettingsTypes.IsoCountryCode &&
        settings.languages.find((l) => l.countryCode === navigatorLanguage)
      ) {
        setChosenLanguage(navigatorLanguage as SettingsTypes.IsoCountryCode);
      } else if (
        settings.languages.find(
          (language: SettingsTypes.Language) =>
            language.countryCode === SettingsTypes.IsoCountryCode.EN,
        )
      ) {
        setChosenLanguage(SettingsTypes.IsoCountryCode.EN);
      } else {
        setChosenLanguage(
          settings.languages[0].countryCode as SettingsTypes.IsoCountryCode,
        );
      }
      return true;
    } catch (error) {
      console.log("Can't load customer", error);
      return false;
    }
  };

  const setLanguage = (isoCode: SettingsTypes.IsoCountryCode) => {
    for (let language of languages) {
      if (language.countryCode === isoCode) {
        setChosenLanguage(isoCode);

        return;
      }
    }
  };

  React.useEffect(() => {
    if (chosenLanguage !== null) {
      setLoginDescriptionByIsoCode(chosenLanguage);
      setRetoureDescriptionByIsoCode(chosenLanguage);
      setLocaleReasonsByIsoCode(chosenLanguage);
      i18nInstance.changeLanguage(chosenLanguage.toLocaleLowerCase());
    }
  }, [chosenLanguage]);

  return (
    <settingsContext.Provider
      value={{
        primaryColor,
        primaryContrastColor,
        secondaryColor,
        customerImprint,
        customerPrivacyPolicy,
        backgroundUrl,
        logoUrl,
        descriptions,
        customer,
        processTypeId,
        languages,
        chosenLanguage: chosenLanguage
          ? chosenLanguage
          : SettingsTypes.IsoCountryCode.EN,
        loginDescription,
        retoureDescription,
        reasons,
        localeReasons,
        actions: {
          getSettings,
          setLanguage,
        },
        forceEmptyReturnShipment,
      }}
    >
      {props.children}
    </settingsContext.Provider>
  );
};

export default SettingsProvider;
