import React, { useContext, Fragment } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
  useHistory,
} from "react-router-dom";
import LoginPage from "./pages/Login-Page/LoginPage";
import DynamicThemeProvider from "./components/Dynamic-Theme-Provider/DynamicThemeProvider";
import { settingsContext } from "./contexts/SettingsContext";
import InitialLoadingPage from "./pages/Initial-Loading-Page/InitialLoadingPage";
import "./index.css";
import {
  createStyles,
  CssBaseline,
  makeStyles,
  Theme,
} from "@material-ui/core";
import Error from "./types/error.types";
import NotFound from "./components/Not-Found/NotFound";
import RetourePage from "./pages/Retoure-Page/RetourePage";
import Footer from "./components/Footer/Footer";
import classes from "*.module.css";

export enum ProcessTypeId {
  DE_CH = 1,
  DE_GB = 2,
}

export enum ProcessTypeIdentifier {
  DE_CH = "de-ch",
  DE_GB = "de-gb",
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    siteWrapper: {
      position: "relative",
      minHeight: "100vh",
      margin: 0,
      padding: 0,
    },
    footerWrapper: {
      position: "absolute",
      bottom: 0,
      right: 0,
      margin: 0,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  })
);

const App = () => {
  const classes = useStyles();
  const settings = useContext(settingsContext);
  const location = useLocation();
  const history = useHistory();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<Error>({
    message: "",
    open: false,
  });

  const getSettings = async () => {
    setIsLoading(true);

    const customer = location.pathname.split("/")[1];
    const processTypeId =
      location.pathname.split("/")[2].toLowerCase() ===
      ProcessTypeIdentifier.DE_GB
        ? ProcessTypeId.DE_GB
        : ProcessTypeId.DE_CH;
    const success = await settings.actions.getSettings(customer, processTypeId);
    if (success) {
      setIsLoading(false);
    } else {
      setError({ message: "Der angegebene Link existiert nicht", open: true });
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    getSettings();
  }, []);

  return (
    <Fragment>
      {!isLoading ? (
        !error.open ? (
          <DynamicThemeProvider
            primaryColor={settings.primaryColor ?? ""}
            primaryContrastColor={settings.primaryContrastColor ?? ""}
            secondaryColor={settings.secondaryColor ?? ""}
            backgroundUrl={settings.backgroundUrl}
          >
            <CssBaseline />
            <div className={classes.siteWrapper}>
              <div
                style={{
                  paddingBottom: 96,
                  margin: 0,
                }}
              >
                <Switch>
                  <Route path="/:customer/:processTypeIdentifier/retoure">
                    <LoginPage />
                  </Route>
                  <Route path="/:customer/retoure">
                    <LoginPage />
                  </Route>
                  <Route path="/:customer/:processTypeIdentifier/retoure-management">
                    <Fragment>
                      <RetourePage />
                    </Fragment>
                  </Route>
                  <Route path="/:customer/retoure-management">
                    <Fragment>
                      <RetourePage />
                    </Fragment>
                  </Route>
                </Switch>
              </div>
              <div className={classes.footerWrapper}>
                <Footer
                  imprintUrl={settings.customerImprint}
                  privacyPolicyUrl={settings.customerPrivacyPolicy}
                ></Footer>
              </div>
            </div>
          </DynamicThemeProvider>
        ) : (
          <NotFound errorMessage={error.message} />
        )
      ) : (
        <InitialLoadingPage />
      )}
    </Fragment>
  );
};

export default App;
