import {
  Button,
  createStyles,
  makeStyles,
  Modal,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { FC } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Item } from "../RetourePage";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(3, 5, 4),
    },
    modalBody: {
      top: "50%",
      left: "50%",
      transform: `translate(-50%, -50%)`,
      width: "80%",
      maxHeight: "80%",
      overflow: "scroll",
    },
    buttonGroup: {
      display: "flex",
      marginTop: theme.spacing(4),
      alignItems: "center",
    },
    button: {
      marginRight: theme.spacing(1),
    },
    headingWrapper: {
      marginBottom: theme.spacing(2),
    },
  })
);

export interface SubmitRetoureModalProps {
  open: boolean;
  retoureItems: Item[];
  onClose: () => void;
  onSubmit: () => void;
}

const SubmitRetoureModal: FC<SubmitRetoureModalProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="submit-retoure-modal-title"
      aria-describedby="submit-retoure-modal-description"
    >
      <div className={clsx(classes.modalBody, classes.paper)}>
        <div className={classes.headingWrapper}>
          <Typography variant="h4">{t("submitRetoureModalHeading")}</Typography>
        </div>
        <div>
          <Typography paragraph>
            {t("submitRetoureModalDescription")}
          </Typography>
          <ul>
            {props.retoureItems.map((item) => (
              <li>{item.name}</li>
            ))}
          </ul>
        </div>
        <div className={classes.buttonGroup}>
          <div>
            <Button
              className={classes.button}
              color="primary"
              variant="contained"
              onClick={props.onSubmit}
            >
              {t("submitRetoure")}
            </Button>
          </div>
          <div>
            <Button onClick={props.onClose}>{t("cancel")}</Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SubmitRetoureModal;
