import React, { FC, useContext, Fragment } from "react";
import {
  Button,
  makeStyles,
  Grid,
  Paper,
  createStyles,
  Theme,
  Snackbar,
  Card,
  Typography,
  withStyles,
  Select,
  MenuItem,
  CircularProgress,
  Menu,
} from "@material-ui/core";
import { settingsContext } from "../../contexts/SettingsContext";
import InfoIcon from "@material-ui/icons/Info";
import LanguagePicker from "../../components/Langauge-Picker/LanguagePicker";
import DeleteIcon from "@material-ui/icons/Delete";
import * as LineItemsService from "./../../services/lineItem.service";
import Error from "./../../types/error.types";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import queryString from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import * as LineItemTypes from "./../../types/lineitem.types";
import * as RetoureService from "./../../services/retoure.service";
import jsPDF from "jspdf";
import { useTranslation } from "react-i18next";
import PrintIcon from "@material-ui/icons/Print";
import SubmitRetoureModal from "./components/SubmitRetoureModal";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    logoWrapper: {},
    logo: {
      width: "100%",
    },
    infoBoxWrapper: {
      width: "100%",
    },
    infoBox: {
      width: "100%",
      padding: theme.spacing(3),
      marginBottom: theme.spacing(3),
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
    infoIconWrapper: {
      //paddingRight: theme.spacing(2),
      maxWidth: "45px",
    },
    langPickerWrapper: {
      display: "flex",
      justifyContent: "flex-end",
    },
    descriptionWrapper: {
      position: "relative",
    },
    textField: {
      marginTop: theme.spacing(3),
    },
    button: {
      [theme.breakpoints.up("md")]: {
        width: "auto",
      },
      width: "100%",
      marginTop: theme.spacing(1),
    },
    pannelWrapper: {
      width: "100%",
      [theme.breakpoints.up("md")]: {
        marginTop: "40px",
      },
    },
    childOrderWrapper: {
      marginTop: theme.spacing(10),
    },
    returnedLineItemsWrapper: {
      marginBottom: theme.spacing(2),
    },
    lineItemCard: {
      wdith: "100%",
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
      boxShadow: "0 0 0 0 ",
    },
    retoureHeaderCard: {
      padding: theme.spacing(2),
      marginBottom: "-10px",
      paddingBottom: "30px",

      boxShadow: "0 0 0 0 ",
    },
    retoureHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      minHeight: "40px",
    },
    printLabelButton: {
      width: "auto",
    },
    printIcon: {
      marginRight: theme.spacing(1),
    },
    returnedLineItemCard: {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.grey[500],
      padding: theme.spacing(2),
      paddingTop: "30px",
      marginTop: "-10px",
      //marginBottom: theme.spacing(2),
      boxShadow: "0 0 0 0 ",
    },
    cardContent: {
      width: "100%",
      //display: "flex",
      //justifyContent: "flex-end",
      //alignItems: "center",
      //minHeight: 85,
    },
    dropDownButtonWrapper: {
      width: "100%",
      minHeight: 60,
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    dropDownButton: {
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(2),
      },
    },
    selectedDropDownButtonWrapper: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(2),
      },
    },
    selectedDropDownButton: {},
    returnWrapper: {
      height: "100%",
      display: "flex",
      justifyContent: "flex-end",
      alignContent: "center",
    },
    returnOptions: {
      width: "100%",
      display: "flex",
      [theme.breakpoints.up("xs")]: {
        flexDirection: "column-reverse",
        alignItems: "flex-end",
        justifyContent: "right",
      },
    },
    reasonSelect: {
      maxWidth: "100%",
      [theme.breakpoints.up("xs")]: {
        marginRight: theme.spacing(0),
        marginTop: theme.spacing(2),
      },
    },
  })
);

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ReasonSelect = withStyles((theme: Theme) =>
  createStyles({
    root: {
      boxSizing: "border-box",
      height: 32,
      padding: 8,
      visibility: "hidden",
      "&:hover": {
        cursor: "default",
      },
      //width: 224,
    },
    outlined: {
      visibility: "hidden",
    },
    icon: {
      visibility: "hidden",
      color: theme.palette.primary.main,
    },
  })
)(Select);

interface DropdownButtonProps {
  title: string;
  checked: boolean;
  onChange: (event: any) => void;
  returnReason: string;
  returnReasonText: string;
}

const DropdownButton: FC<DropdownButtonProps> = (props) => {
  const classes = useStyles();
  return (
    <Fragment>
      <div className={classes.dropDownButtonWrapper}>
        {!props.checked || !props.returnReason ? (
          <div style={{ width: "100%" }}>
            <Button
              className={classes.dropDownButton}
              fullWidth
              variant="contained"
              color="primary"
              onClick={props.onChange}
            >
              {props.title.toUpperCase()} <ArrowDropDownIcon />
            </Button>
          </div>
        ) : (
          <div className={classes.selectedDropDownButtonWrapper}>
            <div style={{ marginRight: "10px", textAlign: "right" }}>
              <Typography variant="body1">{props.returnReasonText}</Typography>
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={props.onChange}
                className={classes.selectedDropDownButton}
              >
                <DeleteIcon fontSize="small" />
              </Button>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export interface Item {
  id: number;
  externalId: string;
  name: string;
  retoure: boolean;
  returnReason: string;
  returnReasonText: string;
  reasonSelectOpen: boolean;
}

interface returnedItem {
  id: number;
  externalId: string;
  name: string;
  returnReasonId: number;
}

interface LabelData {
  name: string;
  data: string;
}

const RetourePage: FC<{}> = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const settings = useContext(settingsContext);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isLoadingLabel, setIsLoadingLabel] = React.useState<any>({
    childOrderId: -1,
    loading: false,
  });
  const [error, setError] = React.useState<Error>({
    message: "",
    open: false,
  });
  const [success, setSuccess] = React.useState<Error>({
    message: "",
    open: false,
  });
  const [orderId, setOrderId] = React.useState<number>(-1);
  const [selectableLineItems, setSelectableLineItems] = React.useState<Item[]>(
    []
  );

  const [childOrders, setChildOrders] = React.useState<
    LineItemTypes.childOrder[]
  >([]);

  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const labelAnchorRef = React.useRef<HTMLAnchorElement | null>(null);
  const [labelData, setLabelData] = React.useState<LabelData | null>(null);

  const getLineItems = async () => {
    try {
      const res = await LineItemsService.getLineItems(
        queryParams.email,
        decodeURIComponent(queryParams.trackingNumber as string),
        settings.customer
      );
      //FIXME: Add additional Types
      const data = res.data;
      const orderId = data.orderId;
      const parentLineItems = data.lineItems;
      const childOrders: LineItemTypes.childOrder[] = data.shipments;

      let newSelectableLineItems: Item[] = [];
      for (let item of parentLineItems) {
        newSelectableLineItems.push({
          id: item.id,
          externalId: item.externalId,
          name: item.name,
          retoure: false,
          returnReason: "",
          returnReasonText: "",
          reasonSelectOpen: false,
        });
      }
      setSelectableLineItems(newSelectableLineItems);
      setChildOrders(childOrders);
      setOrderId(orderId);
      //TODO: Correct Error handlung and loader Component
    } catch (error) {
      if (error.response) {
        switch (error.response.data.statusCode) {
          case 404:
            setError({
              message:
                "Sorry, we couldn't find your Order. Please check your e-mail and tracking number and try again",
              open: true,
            });
            break;

          default:
            break;
        }
      } else if (error.request) {
      } else {
        console.log(error);
      }
    }
  };

  const getLabel = async (childOrderId: number) => {
    try {
      const res = await RetoureService.getLabel(
        queryParams.email,
        childOrderId,
        settings.customer
      );
      const data = res.data;

      if (
        data.labelDefinition.imageFileType === RetoureService.LabelTypes.PNG
      ) {
        createPDFfromLabel(
          `data:image/"${data.labelDefinition.imageFileType};base64, ${data.item.label[0]}`
        );
      } else if (
        data.labelDefinition.imageFileType === RetoureService.LabelTypes.PDF
      ) {
        setLabelData({
          name: `retoure_label_${decodeURIComponent(
            queryParams.trackingNumber as string
          )}_${queryParams.email}.pdf`,
          data: `data:application/pdf;base64,${data.item.label[0]}`,
        });
      }
    } catch (error) {}
  };

  const createPDFfromLabel = (label: string) => {
    let doc = new jsPDF({ orientation: "landscape", format: "a6" });
    doc.addImage({
      imageData: label,
      x: 0,
      y: 0,
      width: 148,
      height: 105,
      //rotation: 90,
    });
    doc.save(
      `retoure_label_${decodeURIComponent(
        queryParams.trackingNumber as string
      )}_${queryParams.email}.pdf`
    );
  };

  const handleRetoureChange = (id: number, anchorEl: HTMLElement) => {
    setAnchorEl(anchorEl);
    const index = selectableLineItems.findIndex((item) => item.id === id);
    let newLineItems = [...selectableLineItems];
    newLineItems[index].retoure = !newLineItems[index].retoure;
    if (newLineItems[index].retoure === true) {
      newLineItems[index].reasonSelectOpen = true;
    } else {
      newLineItems[index].reasonSelectOpen = false;
      newLineItems[index].returnReason = "";
    }
    setSelectableLineItems(newLineItems);
  };

  const handleReasonChange = (
    id: number,
    value: string,
    reasonText: string
  ) => {
    const index = selectableLineItems.findIndex((item) => item.id === id);
    let newLineItems = [...selectableLineItems];
    newLineItems[index].returnReason = value;
    newLineItems[index].returnReasonText = reasonText;
    newLineItems[index].reasonSelectOpen = false;
    setSelectableLineItems(newLineItems);
  };

  const handleReasonOpen = (lineItem: Item) => {
    const index = selectableLineItems.findIndex(
      (item) => item.id === lineItem.id
    );
    let newLineItems = [...selectableLineItems];
    newLineItems[index].reasonSelectOpen = true;
    setSelectableLineItems(newLineItems);
  };

  const handleReasonClose = (lineItem: Item) => {
    const index = selectableLineItems.findIndex(
      (item) => item.id === lineItem.id
    );
    let newLineItems = [...selectableLineItems];
    newLineItems[index].reasonSelectOpen = false;
    setSelectableLineItems(newLineItems);
  };

  const handlePrintLabel = async (childOrderId: number) => {
    setIsLoadingLabel({
      childOrderId: childOrderId,
      loading: true,
    });
    await getLabel(childOrderId);
    setIsLoadingLabel({
      childOrderId: -1,
      loading: false,
    });
  };

  React.useEffect(() => {
    let newLineItems = [...selectableLineItems];
    let changedItems: boolean = false;
    for (let item of newLineItems) {
      if (item.retoure && item.returnReason === "" && !item.reasonSelectOpen) {
        changedItems = true;
        item.retoure = false;
      }
    }
    if (changedItems) {
      setSelectableLineItems(newLineItems);
    }
  }, [selectableLineItems]);

  const handleModalSubmit = async () => {
    setModalOpen(false);
    setIsLoading(true);
    let body: RetoureService.putRetoureProps = {
      orderId: orderId,
      state: "retoureAnnounced",
      lineItems: [],
    };
    for (let item of selectableLineItems) {
      if (item.retoure === true) {
        body.lineItems.push({
          id: item.id,
          externalId: item.externalId,
          retour: true,
          //FIXME: Exchange with real reasonId
          returnReasonId: parseInt(item.returnReason),
        });
      }
    }

    try {
      await RetoureService.putRetoure(
        body,
        queryParams.email,
        decodeURIComponent(queryParams.trackingNumber as string),
        settings.customer,
        settings.processTypeId
      );
      await getLineItems();
      setSuccess({ message: t("retoureSucceeded"), open: true });
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (isLoading === false) {
      setModalOpen(true);
    }
  };

  React.useEffect(() => {
    getLineItems();
  }, []);

  React.useEffect(() => {
    let newSelectableLineItems = [...selectableLineItems];
    for (let item of newSelectableLineItems) {
      let newText: string;
      let reason = settings.localeReasons.find(
        (reason) => reason.reasonId === Number(item.returnReason)
      );
      if (reason) {
        console.log(reason);
        item.returnReasonText = reason.text;
      }
    }
    console.log(newSelectableLineItems);
    setSelectableLineItems(newSelectableLineItems);
  }, [settings.localeReasons]);

  React.useEffect(() => {
    if (labelAnchorRef.current !== null && labelData !== null) {
      labelAnchorRef.current.click();
    }
  }, [labelData]);

  return (
    <Fragment>
      <div className={classes.root}>
        <a
          href={labelData?.data || "./"}
          download={labelData?.name || ""}
          style={{ display: "none" }}
          ref={labelAnchorRef}
        >
          "Hello World"
        </a>
        <div>
          <Grid container spacing={1}>
            <Grid container spacing={1}>
              <Grid item xs={4} md={1}>
                <div className={classes.logoWrapper}>
                  <img
                    className={classes.logo}
                    src={settings.logoUrl}
                    alt="Logo"
                  />
                </div>
              </Grid>
              <Grid item xs={8} md={11}>
                <div className={classes.langPickerWrapper}>
                  <LanguagePicker />
                </div>
              </Grid>
            </Grid>
            <Grid item md={2} xs={false}></Grid>
            <Grid container item xs={12} md={7}>
              <Grid item xs={12}>
                <div className={classes.infoBoxWrapper}>
                  <Paper className={classes.infoBox} variant="outlined" square>
                    <div className={classes.infoIconWrapper}>
                      <InfoIcon />
                    </div>
                    <div className={classes.descriptionWrapper}>
                      <p>{settings.retoureDescription}</p>
                    </div>
                  </Paper>
                </div>
              </Grid>
              <Grid container item xs={12}>
                <div className={classes.pannelWrapper}>
                  <div>
                    <form onSubmit={handleSubmit} noValidate>
                      <div style={{ width: "100%" }}>
                        {!settings.forceEmptyReturnShipment &&
                          selectableLineItems.map(
                            (lineItem: Item, index: number) => (
                              <Card
                                key={lineItem.id}
                                className={classes.lineItemCard}
                              >
                                {/*                               {index === 0 && (
                                <Grid container item xs={12}>
                                  <Grid item xs={7} md={9}>
                                    <h2>{t("product")}</h2>
                                  </Grid>
                                  <Grid item xs={5} md={3}>
                                    <h2 style={{ textAlign: "right" }}>
                                      {t("return")}
                                    </h2>
                                  </Grid>
                                </Grid>
                              )} */}

                                <Grid
                                  className={classes.cardContent}
                                  item
                                  container
                                  justify="space-between"
                                  xs={12}
                                >
                                  <Grid
                                    container
                                    item
                                    xs={12}
                                    md={7}
                                    alignContent="center"
                                  >
                                    <Typography>{lineItem.name}</Typography>
                                  </Grid>
                                  <Grid item xs={12} md={"auto"}>
                                    <div className={classes.returnWrapper}>
                                      {
                                        <DropdownButton
                                          title={t("return")}
                                          checked={lineItem.retoure}
                                          onChange={(event) =>
                                            handleRetoureChange(
                                              lineItem.id,
                                              event.currentTarget
                                            )
                                          }
                                          returnReason={lineItem.returnReason}
                                          returnReasonText={
                                            lineItem.returnReasonText
                                          }
                                          //className={classes.dropDownButton}
                                        />
                                      }
                                    </div>
                                    <div className={classes.returnOptions}>
                                      {lineItem.retoure && (
                                        <Menu
                                          id="simple-menu"
                                          anchorEl={anchorEl}
                                          keepMounted
                                          open={lineItem.reasonSelectOpen}
                                          onClose={(event) =>
                                            handleReasonClose(lineItem)
                                          }
                                        >
                                          <MenuItem value={-1} disabled>
                                            {t("reason")}
                                          </MenuItem>
                                          {settings.localeReasons.map(
                                            (reason) => (
                                              <MenuItem
                                                onClick={(event) =>
                                                  handleReasonChange(
                                                    lineItem.id,
                                                    reason.reasonId.toString() as string,
                                                    reason.text
                                                  )
                                                }
                                                key={reason.reasonId}
                                                value={reason.reasonId}
                                              >
                                                {reason.text}
                                              </MenuItem>
                                            )
                                          )}
                                        </Menu>
                                      )}
                                    </div>
                                  </Grid>
                                </Grid>
                              </Card>
                            )
                          )}
                      </div>

                      <Grid item xs={12} md={12}>
                        {(selectableLineItems.length > 0 ||
                          settings.forceEmptyReturnShipment) && (
                          <Button
                            className={classes.button}
                            color="primary"
                            variant="contained"
                            onClick={handleSubmit}
                            disabled={
                              settings.forceEmptyReturnShipment
                                ? false
                                : selectableLineItems.find(
                                    (item) =>
                                      item.retoure === true &&
                                      item.returnReason !== ""
                                  ) === undefined
                            }
                          >
                            {isLoading ? (
                              <CircularProgress color="secondary" size={22} />
                            ) : (
                              t("submitRetoure")
                            )}
                          </Button>
                        )}
                      </Grid>
                    </form>
                    <Grid item xs={12}>
                      <div className={classes.childOrderWrapper}>
                        {childOrders.map((childOrder, index) => (
                          <div className={classes.returnedLineItemsWrapper}>
                            <Card className={classes.retoureHeaderCard}>
                              <div className={classes.retoureHeader}>
                                <Typography>{`${t("retoure")} ${
                                  index + 1
                                }`}</Typography>
                                <Button
                                  className={classes.printLabelButton}
                                  color="primary"
                                  variant="contained"
                                  onClick={() =>
                                    handlePrintLabel(childOrder.shipmentId)
                                  }
                                >
                                  {isLoadingLabel.childOrderId ===
                                    childOrder.shipmentId &&
                                  isLoadingLabel.loading ? (
                                    <CircularProgress
                                      color="secondary"
                                      size={22}
                                    />
                                  ) : (
                                    <Fragment>
                                      <PrintIcon
                                        className={classes.printIcon}
                                      />
                                      {t("printLabel")}
                                    </Fragment>
                                  )}
                                </Button>
                              </div>
                            </Card>
                            {childOrder.lineItems.map(
                              (
                                lineItem: LineItemTypes.lineItem,
                                index: number
                              ) =>
                                lineItem.retour && (
                                  <Card
                                    key={lineItem.id}
                                    className={classes.returnedLineItemCard}
                                  >
                                    <Grid
                                      item
                                      container
                                      xs={12}
                                      spacing={2}
                                      className={classes.cardContent}
                                    >
                                      <Grid item xs={8} md={9}>
                                        <Typography>{lineItem.name}</Typography>
                                      </Grid>
                                      <Grid item xs={4} md={3}>
                                        <div
                                          className={classes.returnOptions}
                                        ></div>
                                      </Grid>
                                    </Grid>
                                  </Card>
                                )
                            )}
                          </div>
                        ))}
                      </div>
                    </Grid>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
      <Snackbar
        open={error.open}
        autoHideDuration={6000}
        onClose={() => setError({ ...error, open: false })}
      >
        <Alert
          onClose={() => setError({ ...error, open: false })}
          severity="warning"
        >
          {error.message}
        </Alert>
      </Snackbar>
      <Snackbar
        open={success.open}
        autoHideDuration={6000}
        onClose={() => setError({ ...error, open: false })}
      >
        <Alert
          onClose={() => setSuccess({ ...success, open: false })}
          severity="success"
        >
          {success.message}
        </Alert>
      </Snackbar>
      <SubmitRetoureModal
        open={modalOpen}
        retoureItems={selectableLineItems.filter(
          (item) => item.retoure === true
        )}
        onClose={handleModalClose}
        onSubmit={handleModalSubmit}
      />
    </Fragment>
  );
};

export default RetourePage;
