import React, { FC } from "react";
import {
  SettingsContext,
  settingsContext,
} from "./../../contexts/SettingsContext";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
  Theme,
  createStyles,
  Input,
} from "@material-ui/core";
import * as SettingsTypes from "./../../types/settings.types";

function countryToFlag(isoCode: string) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectRoot: {
      height: "25px",
      width: "30px",
      borderRadius: "5px",
      backgroundColor: "#FFFFFF",
      padding: theme.spacing(1),
      margin: 0,
      //display: "flex",
      //alignItems: "center",
      //justifyContent: "center",
      "&:hover": {
        borderRadius: "5px",
        backgroundColor: "#FFFFFF",
      },
      "&:focus": {
        borderRadius: "5px",
        backgroundColor: "#FFFFFF",
      },
    },
    selectUnderline: {
      "&&&:before": {
        display: "none",
        borderBottom: "none",
      },
      "&&:after": {
        display: "none",
        borderBottom: "none",
      },
    },
    option: {
      fontSize: 15,
      "& > span": {
        marginRight: 10,
        fontSize: 18,
      },
    },
    renderSelect: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingTop: "1px",
    },
  })
);

const LanguagePicker: FC<{}> = () => {
  const classes = useStyles();
  const settings = React.useContext<SettingsContext>(settingsContext);
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    settings.actions.setLanguage(
      event.target.value as SettingsTypes.IsoCountryCode
    );
  };

  return (
    <div>
      <FormControl fullWidth>
        <Select
          classes={{
            root: classes.selectRoot,
          }}
          input={
            <Input
              classes={{
                underline: classes.selectUnderline,
              }}
            />
          }
          labelId="language-select-label"
          id="language-select"
          value={settings.chosenLanguage}
          renderValue={(value) => (
            <div className={classes.renderSelect}>
              {countryToFlag(value as SettingsTypes.IsoCountryCode)}
            </div>
          )}
          onChange={handleChange}
        >
          {settings.languages.map((language) => (
            <MenuItem autoFocus value={language.countryCode}>
              {countryToFlag(language.countryCode)}{" "}
              {SettingsTypes.IsoLanguage[language.countryCode]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default LanguagePicker;
