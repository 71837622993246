import classes from "*.module.css";
import {
  createStyles,
  makeStyles,
  Theme,
  Typography,
  Link,
} from "@material-ui/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: "5px",

      marginRight: 16,
      marginLeft: 16,
      marginBottom: 16,
      [theme.breakpoints.down("sm")]: {
        marginLeft: 10,
        marginRight: 10,
        marginBottom: 10,
      },
      backgroundColor: "rgba(0, 0, 0, 0.25)",
    },
    linkWrapper: {
      textAlign: "right",
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
      },
      padding: theme.spacing(4),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    linkTypo: {
      color: "#FFFFFF",
      "& > * + *": {
        marginLeft: theme.spacing(2),
      },
    },
    link: {
      color: "#ffffff",
    },
  })
);

export interface FooterProps {
  privacyPolicyUrl: string;
  imprintUrl: string;
}

const Footer: FC<FooterProps> = (props) => {
  let classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      <div className={classes.linkWrapper}>
        <Typography className={classes.linkTypo}>
          <Link
            className={classes.link}
            href={props.imprintUrl}
            target="_blank"
            onClick={(event: any) => event.preventDefault}
            variant="body1"
          >
            {t("imprint")}
          </Link>
        </Typography>
        <Typography className={classes.linkTypo}>
          <Link
            className={classes.link}
            href={props.privacyPolicyUrl}
            target="_blank"
            onClick={(event: React.MouseEvent) => event.preventDefault}
            variant="body1"
          >
            {t("privacyPolicy")}
          </Link>
        </Typography>
      </div>
    </div>
  );
};

export default Footer;
