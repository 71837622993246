import axios from "axios";
import { backendUri } from "../shared/helper";
import { Setting } from "../types/settings.types";

export const getSettings = async (
  customer: string,
  processTypeId: number
): Promise<Setting> => {
  const response = await axios
    .get<Setting>(
      `${backendUri}?customer=${customer}&processTypeId=${processTypeId}`
    )
    .then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  return response.data;
};
