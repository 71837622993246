export interface Reason {
  reasonId: number;
  translations: translation[];
}

export interface Setting {
  id: number;
  backgroundUrl: string;
  logoUrl: string;
  primaryColor: string | null;
  primaryContrastColor: string | null;
  secondaryColor: string | null;
  customerImprint: string | null;
  customerPrivacyPolicy: string | null;
  languages: Language[];
  descriptions: Description[];
  reasons: Reason[];
  forceEmptyReturnShipment: boolean;
}

export interface Language {
  languageId: number;
  countryCode: IsoCountryCode;
  isDefault: boolean;
}

export interface Description {
  descriptionId: number;
  type: DescriptionTypes;
  text: string;
  isoCountryCode: string;
}

export interface translation {
  translationId: number;
  countryCode: IsoCountryCode;
  translation: string;
}

export enum DescriptionTypes {
  DESCRIPTION1 = "description1",
  DESCRIPTION2 = "description2",
}

export enum IsoLanguage {
  AR = "Arabic",
  AZ = "Azerbaijani",
  BE = "Belorussian",
  BG = "Bulgarian",
  BN = "Bengali",
  BS = "Bosnian",
  CA = "Catalan",
  CS = "Czech",
  DA = "Danish",
  DE = "German",
  EN = "English",
  ES = "Spanish",
  ET = "Estonian",
  FA = "Persian",
  FI = "Finnish",
  FR = "French",
  GL = "Galician",
  EL = "Greek",
  HE = "Hebrew",
  HI = "Hindi",
  HR = "Croatian",
  HU = "Hungarian",
  HY = "Armenian",
  IT = "Italian",
  ID = "Indonesian",
  JA = "Japanese",
  KA = "Georgian",
  KK = "Kazakh",
  KO = "Korean",
  KY = "Kyrgyz",
  LT = "Lithuanian",
  LV = "Latvian",
  MK = "Macedonian",
  MN = "Mongolian",
  MS = "Malay",
  NB = "Norwegian Bokmål",
  NL = "Dutch",
  NN = "Norwegian Nynorsk",
  PL = "Polish",
  PT = "Portuguese",
  RO = "Romanian",
  RU = "Russian",
  SK = "Slovak",
  SL = "Slovene",
  SR = "Serbian",
  SV = "Swedish",
  TH = "Thai",
  TR = "Turkish",
  UK = "Ukrainian",
  UR = "Urdu",
  UZ = "Uzbek",
  ZH = "Chinese",
  VI = "Vietnamese",
}

export enum IsoCountryCode {
  AR = "AR",
  AZ = "AZ",
  BE = "BE",
  BG = "BG",
  BN = "BN",
  BS = "BS",
  CA = "CA",
  CS = "CS",
  DA = "DA",
  DE = "DE",
  EN = "EN",
  ES = "ES",
  ET = "ET",
  FA = "FA",
  FI = "FI",
  FR = "FR",
  GL = "GL",
  EL = "EL",
  HE = "HE",
  HI = "HI",
  HR = "HR",
  HU = "HU",
  HY = "HY",
  IT = "IT",
  ID = "ID",
  JA = "JA",
  KA = "KA",
  KK = "KK",
  KO = "KO",
  KY = "KY",
  LT = "LT",
  LV = "LV",
  MK = "MK",
  MN = "MN",
  MS = "MS",
  NB = "NB",
  NL = "NL",
  NN = "NN",
  PL = "PL",
  PT = "PT",
  RO = "RO",
  RU = "RU",
  SK = "SK",
  SL = "SL",
  SR = "SR",
  SV = "SV",
  TH = "TH",
  TR = "TR",
  UK = "UK",
  UR = "UR",
  UZ = "UZ",
  ZH = "ZH",
  VI = "VI",
}
