import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import SettingsProvider from "./contexts/SettingsContext";
import { BrowserRouter as Router } from "react-router-dom";
import "./i18n";

ReactDOM.render(
  <Suspense fallback={null}>
    <Router>
      <SettingsProvider>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </SettingsProvider>
    </Router>
  </Suspense>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
