import {
  Button,
  createStyles,
  FormControl,
  FormGroup,
  Grid,
  makeStyles,
  Paper,
  Snackbar,
  TextField,
  Theme,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import InfoIcon from "@material-ui/icons/Info";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import clsx from "clsx";
import React, { FC, Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import LanguagePicker from "../../components/Langauge-Picker/LanguagePicker";
import { settingsContext } from "../../contexts/SettingsContext";
import * as LineItemsService from "./../../services/lineItem.service";
import Error from "./../../types/error.types";
import { ProcessTypeId, ProcessTypeIdentifier } from "../../App";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    logoWrapper: {},
    logo: {
      width: "100%",
    },
    infoBoxWrapper: {
      marginTop: "20px",
      backgroundColor: "blue",
      width: "100%",
    },
    infoBox: {
      width: "100%",
      padding: theme.spacing(3),
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
    infoIconWrapper: {
      //paddingRight: theme.spacing(2),
      maxWidth: "45px",
    },
    langPickerWrapper: {
      display: "flex",
      justifyContent: "flex-end",
    },
    descriptionWrapper: {
      position: "relative",
    },
    textField: {
      boxSizing: "border-box",
      marginTop: theme.spacing(3),
    },
    textFieldError: {
      border: `2px solid ${theme.palette.error.main}`,
    },
    helper: {
      maxWidth: "100%",
      padding: theme.spacing(1),
      color: "#fff",
      backgroundColor: theme.palette.error.main,
      display: "flex",
      alignItems: "center",
      opacity: 0.8,
    },
    button: {
      [theme.breakpoints.up("md")]: {
        width: "auto",
      },
      width: "100%",
      marginTop: theme.spacing(3),
    },
    pannelWrapper: {
      width: "100%",
      [theme.breakpoints.up("md")]: {
        marginTop: "40px",
      },
    },
  })
);

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const LoginPage: FC<{}> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const settings = useContext(settingsContext);
  const [error, setError] = React.useState<Error>({
    message: "",
    open: false,
  });
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>("");
  const [trackingNumber, setTrackingNumber] = React.useState<string>("");
  const [emailValidation, setEmailValidation] = React.useState<boolean>(true);
  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const handleChangeTrackingNumber = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTrackingNumber(event.target.value);
  };

  const handleEmailValidation = (value: string) => {
    if (
      !/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
        value
      )
    ) {
      setEmailValidation(false);
    } else {
      setEmailValidation(true);
    }
  };

  const getLineItems = async () => {
    const processTypeIdentifier =
      settings.processTypeId === ProcessTypeId.DE_GB
        ? ProcessTypeIdentifier.DE_GB
        : ProcessTypeIdentifier.DE_CH;

    setIsLoading(true);
    try {
      await LineItemsService.getLineItems(
        email,
        trackingNumber,
        settings.customer
      );
      setIsLoading(false);
      history.push(
        `/${
          settings.customer
        }/${processTypeIdentifier}/retoure-management?email=${email}&trackingNumber=${encodeURIComponent(
          trackingNumber
        )}`
      );
    } catch (error) {
      if (error.response) {
        switch (error.response.data.statusCode) {
          case 500:
            setError({
              message: t("errorOrderNotFound"),
              open: true,
            });
            break;
          case 400:
            setError({
              message: t("errorOrderNotFound"),
              open: true,
            });
            break;
          case 404:
            setError({
              message: t("errorOrderNotFound"),
              open: true,
            });
            break;

          default:
            setError({
              message: t("errorNoResponse"),
              open: true,
            });
            break;
        }
      } else if (error.request) {
        setError({
          message: t("errorNoResponse"),
          open: true,
        });
      } else {
        setError({
          message: t("errorNoResponse"),
          open: true,
        });
      }
      setIsLoading(false);
    }
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (
      emailValidation &&
      email !== "" &&
      trackingNumber !== ""
    ) {
      getLineItems();
    } else {
      setError({
        message: t("errorFieldsMustBeFilled"),
        open: true,
      });
    }
  };

  return (
    <Fragment>
      <div className={classes.root}>
        <div>
          <Grid container spacing={1}>
            <Grid container spacing={1}>
              <Grid item xs={4} md={1}>
                <div className={classes.logoWrapper}>
                  <img
                    className={classes.logo}
                    src={settings.logoUrl}
                    alt="Logo"
                  />
                </div>
              </Grid>
              <Grid item xs={8} md={11}>
                <div className={classes.langPickerWrapper}>
                  <LanguagePicker />
                </div>
              </Grid>
            </Grid>
            <Grid item md={2} xs={false}></Grid>
            <Grid container item xs={12} md={4}>
              <div className={classes.pannelWrapper}>
                <Grid item xs={12}>
                  <div>
                    <form onSubmit={handleSubmit} noValidate>
                      <Grid item xs={12}>
                        <FormGroup>
                          <TextField
                            className={clsx(
                              classes.textField,
                              !emailValidation ? classes.textFieldError : null
                            )}
                            id="email"
                            label={t("email") + "*"}
                            placeholder="example@email.com"
                            error={!emailValidation}
                            value={email}
                            onChange={handleChangeEmail}
                            onBlur={(event) =>
                              handleEmailValidation(event?.target.value)
                            }
                          />
                          <FormControl>
                            <TextField
                              className={clsx(
                                classes.textField,
                              )}
                              id="trackingNumber"
                              label={t("order id") + "*"}
                              value={trackingNumber}
                              onChange={handleChangeTrackingNumber}
                            />
                          </FormControl>
                        </FormGroup>
                      </Grid>
                      <Grid xs={12} md={12}>
                        <Button
                          className={classes.button}
                          color="primary"
                          variant="contained"
                          type="submit"
                        >
                          {isLoading ? (
                            <CircularProgress color="secondary" size={22} />
                          ) : (
                            t("findOrder")
                          )}
                        </Button>
                      </Grid>
                    </form>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.infoBoxWrapper}>
                    <Paper
                      className={classes.infoBox}
                      variant="outlined"
                      square
                    >
                      <div className={classes.infoIconWrapper}>
                        <InfoIcon />
                      </div>
                      <div className={classes.descriptionWrapper}>
                        <p>{settings.loginDescription}</p>
                      </div>
                    </Paper>
                  </div>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Snackbar
        open={error.open}
        autoHideDuration={6000}
        onClose={() => setError({ ...error, open: false })}
      >
        <Alert
          onClose={() => setError({ ...error, open: false })}
          severity="warning"
        >
          {error.message}
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default LoginPage;
