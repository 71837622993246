import axios from "axios";
import { backendUri } from "../shared/helper";

export interface putRetoureProps {
  orderId: number;
  state: "retoureAnnounced";
  lineItems: {
    id: number;
    externalId: string;
    retour: true;
    returnReasonId: number;
  }[];
}

export const putRetoure: any = async (
  body: putRetoureProps,
  email: string,
  trackingNumber: string,
  customer: string,
  processTypeId: number
) => {
  const data = Object.assign(body);
  const res: any = await axios
    .post(
      `${backendUri}/${encodeURIComponent(
        trackingNumber
      )}/retoure?email=${encodeURIComponent(
        email
      )}&customer=${encodeURIComponent(
        customer
      )}&processTypeId=${processTypeId}`,

      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  return res;
};

export const getLabel: any = async (
  email: string,
  childOrderId: number,
  customer: string
) => {
  const res: any = await axios
    .post(
      `${backendUri}/${childOrderId}/label?email=${encodeURIComponent(
        email
      )}&customer=${encodeURIComponent(customer)}`
    )
    .then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  return res;
};

export enum LabelTypes {
  PNG = "png",
  PDF = "pdf",
}
