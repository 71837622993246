import { ThemeProvider } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import React, { FC, Fragment } from "react";
import createSpacing from "@material-ui/core/styles/createSpacing";

export interface DynamicThemeProviderProps {
  children: React.ReactNode;
  primaryColor: string;
  primaryContrastColor: string;
  secondaryColor: string;
  backgroundUrl: string;
}

const DynamicThemeProvider: FC<DynamicThemeProviderProps> = (props) => {
  const dynamicTheme = () => {
    return createMuiTheme({
      palette: {
        primary: {
          main: props.primaryColor,
          contrastText: props.primaryContrastColor
        },
        secondary: {
          main: props.secondaryColor,
        },
      },
      overrides: {
        MuiCssBaseline: {
          "@global": {
            body: {
              backgroundImage: `url(${props.backgroundUrl})`,
              backgroundPosition: "center top",
              backgroundRepeat: "no-repeat",
              backgroundAttachment: "fixed",
              backgroundSize: "cover",
              backgroundColor: props.secondaryColor,
            },
          },
        },
        MuiTextField: {
          root: {
            backgroundColor: "#FFFFFF",
            borderRadius: "5px",
            padding: "5px",
            height: "60px",
          },
        },
        MuiInput: {
          root: {},
        },
        MuiButton: {
          contained: {
            "&:disabled": {
              color: "rgba(130, 130, 130, 1)",
              opacity: 1,
              backgroundColor: "rgba(192, 192, 192, 1)",
            },
          },
        },
      },
      props: {
        MuiTextField: {
          InputProps: {
            disableUnderline: true,
            style: { marginLeft: "20px" },
          },
          InputLabelProps: { style: { marginLeft: "20px" } },
        },
        MuiButton: {
          disableElevation: true,
        },
      },
    });
  };

  return (
    <Fragment>
      <ThemeProvider theme={dynamicTheme()}>{props.children}</ThemeProvider>
    </Fragment>
  );
};

export default DynamicThemeProvider;
