import axios from "axios";
import { backendUri } from "../shared/helper";

export const getLineItems: any = async (
  email: string,
  trackingNumber: string,
  customer: string
) => {
  const res: any = await axios
    .get(
      `${backendUri}/${encodeURIComponent(
        trackingNumber
      )}/retoure?email=${encodeURIComponent(
        email
      )}&customer=${encodeURIComponent(customer)}`
    )
    .then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    )
    .catch();
  return res;
};
