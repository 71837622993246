import { Typography } from "@material-ui/core";
import React, { FC } from "react";

export interface NotFoundProps {
  errorMessage: string;
}

const NotFound: FC<NotFoundProps> = (props) => {
  return (
    <div
      style={{
        margin: 0,
        padding: 0,
        width: "100%",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography variant="h4">{props.errorMessage}</Typography>
    </div>
  );
};

export default NotFound;
